import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import { Button, Card, DatePicker, Input, Layout, Select } from "antd";
import { observer } from "mobx-react-lite";
import { Typography } from "antd";
import { cutString, generateRandomStringForSeed } from "@/utils/strings";
import { useUserStore } from "@/providers/BaseStoresProvider";
import type { DatePickerProps, TableColumnsType } from "antd";
import { Link } from "react-router-dom";
import { DatePickerType } from "antd/es/date-picker";
import dayjs from "dayjs";

const { Title, Text } = Typography;

const { TextArea } = Input;

export enum GAMES_TYPES {
  CRASH = "0",
  LOTTERY = "1",
  LIMBO = "2",
  HASHDICE = "3",
  CLASSICDICE = "4",
  COINFLIP = "5",
  MINES = "6",
  PLINKO = "7"
}

export const CommentsPage = observer((): JSX.Element => {
  const userStore = useUserStore();
  const [textComment, setTextComm] = useState<string>("");
  const [userId, setUserId] = useState<string>("64f9c0f3036910f66260c84c");
  const [nameComment, setNameComm] = useState<string>(
    generateRandomStringForSeed(5)
  );
  const [dateComment, setDate] = useState<any>();
  const [currGame, setCurrGame] = useState<GAMES_TYPES>(GAMES_TYPES.CRASH);

  const handleChange = (val: any) => {
    setCurrGame(val);
  };

  const onSubmit = () => {
    userStore.createAdminComment({
      text: textComment,
      id: userId,
      date: dateComment?.unix(),
      game: currGame
    });
  };

  const onText = async (value: any) => {
    setTextComm(value.target.value);
  };

  const onName = async (value: any) => {
    setNameComm(value.target.value);
  };

  const onUserId = async (value: any) => {
    setUserId(value.target.value);
  };

  const onChangeDate: DatePickerProps["onChange"] = (date, dateString) => {
    setDate(date);
  };

  return (
    <Layout style={{ padding: "24px" }}>
      <Layout.Content
        style={{
          padding: 24,
          margin: 0,
          minHeight: 280
        }}
      >
        <Typography>
          <Title>Create comment</Title>
          <Card style={{ margin: "20px 0 40px 0" }} type="inner">
            <Select
              defaultValue={currGame}
              style={{ width: "100%", marginBottom: "20px" }}
              onChange={handleChange}
              options={Object.entries(GAMES_TYPES).map(([key, val]) => ({
                value: val,
                label: key
              }))}
            />
            <Input
              style={{ width: "100%", marginBottom: "20px" }}
              placeholder="User name (not required)"
              onInput={onName}
              value={nameComment}
            />
            <Input
              style={{ width: "100%", marginBottom: "20px" }}
              placeholder="User id (required)"
              onInput={onUserId}
              value={userId}
            />
            <DatePicker
              value={dateComment}
              onChange={onChangeDate}
              style={{ width: "100%", marginBottom: "20px" }}
            />
            <TextArea
              style={{ width: "100%", marginBottom: "20px" }}
              placeholder="Comment text"
              size="large"
              onInput={onText}
              value={textComment}
            />
            <Button onClick={onSubmit} type="primary" style={{ width: "100%" }}>
              SUBMIT
            </Button>
          </Card>
        </Typography>
        {/* <Title>TRIGGER</Title>
        <Button
          onClick={userStore.initReset}
          type="primary"
          style={{ width: "100%" }}
        >
          SUBMIT
        </Button> */}
      </Layout.Content>
    </Layout>
  );
});
