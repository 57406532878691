import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Layout, MenuProps, Menu, Switch, Typography, Col } from "antd";
import {
  IoSettings,
  IoAnalytics,
  IoGameController,
  IoBarbell,
  IoBasketOutline,
  IoBatteryFullSharp,
  IoCubeSharp,
  IoDiscSharp,
  IoSwapHorizontal
} from "react-icons/io5";
import ROUTES from "@/routes";
import { useUserStore } from "@/providers/BaseStoresProvider";

const containKeys = (keysA: string[], keysB: string[]) => {
  if (keysB.includes(keysA[1])) return true;
  return false;
};

const { Text } = Typography;

const items: MenuProps["items"] = [
  {
    key: ROUTES.index.path,
    label: "Main",
    icon: <IoAnalytics style={{ width: "20px", height: "20px" }} />
  },
  {
    key: ROUTES.manage.path,
    label: "Manage",
    icon: <IoSettings style={{ width: "20px", height: "20px" }} />
  },
  {
    key: ROUTES.lottery.path,
    label: "Lottery",
    icon: <IoGameController style={{ width: "20px", height: "20px" }} />
  },
  {
    key: ROUTES.referrals.path,
    label: "Referrals",
    icon: <IoBarbell style={{ width: "20px", height: "20px" }} />
  },
  {
    key: ROUTES.swaps.path,
    label: "Swaps",
    icon: <IoSwapHorizontal style={{ width: "20px", height: "20px" }} />
  },
  {
    key: ROUTES.banks.path,
    label: "Banks",
    icon: <IoBasketOutline style={{ width: "20px", height: "20px" }} />
  },
  {
    key: ROUTES.slots.path,
    label: "Slots users",
    icon: <IoCubeSharp style={{ width: "20px", height: "20px" }} />
  },
  {
    key: ROUTES.comments.path,
    label: "Comments",
    icon: <IoDiscSharp style={{ width: "20px", height: "20px" }} />
  }
];

export const Navbar = observer(() => {
  const navigate = useNavigate();
  const location = useLocation();
  const userStore = useUserStore();
  const [curKey, setCurKey] = useState("");

  useEffect(() => {
    items.forEach((link) => {
      if (!link?.key) return;
      const result = containKeys(
        link.key.toString().split("/"),
        location.pathname.split("/")
      );
      if (result) setCurKey(link?.key as string);
    });
  }, [location.pathname]);

  const changeTheme = (val: any) => {
    userStore.toggleTheme(val);
  };

  return (
    <Layout.Sider className="sidebar-wrap">
      <Menu
        className="menu-adaptive"
        mode="inline"
        items={items}
        style={{ height: "100%", borderRight: 0 }}
        selectedKeys={[curKey]}
        onSelect={({ key }) => {
          setCurKey(key);
          return navigate(key);
        }}
        onClick={({ key }) => {
          setCurKey(key);
          return navigate(key);
        }}
        inlineCollapsed={true}
        expandIcon
      />
      <Col className="sidebar-wrap__switch">
        <Text>Theme</Text>
        <Switch checked={userStore.themeDark} onChange={changeTheme} />
      </Col>
    </Layout.Sider>
  );
});
