import { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Layout } from "antd";
import { observer } from "mobx-react-lite";
import { Typography, Table } from "antd";
import { cutString } from "@/utils/strings";
import { useUserStore } from "@/providers/BaseStoresProvider";
import type { TableColumnsType } from "antd";
import { Link } from "react-router-dom";

const { Title, Text } = Typography;

export const SlotsPage = observer((): JSX.Element => {
  const [isLoading, setLoading] = useState<boolean>(true);
  const userStore = useUserStore();

  const loadRequests = useCallback(async () => {
    setLoading(true);
    await userStore.loadSlotsUsers();
    setLoading(false);
  }, []);

  useEffect(() => {
    loadRequests();
  }, [loadRequests]);

  const columns: TableColumnsType<any> = useMemo(
    () => [
      {
        title: "DB ID",
        dataIndex: "_id",
        key: "_id",
        render: (val: string) => (
          <Text
            copyable={{
              text: val ?? ""
            }}
          >
            {cutString(val, 4, 5)}
          </Text>
        )
      },
      {
        title: "User ID",
        dataIndex: "userId",
        key: "userId",
        render: (val: string) => (
          <Text
            copyable={{
              text: val ?? ""
            }}
          >
            {cutString(val, 4, 5)}
          </Text>
        )
      },
      {
        title: "Total bets",
        dataIndex: "betData",
        key: "betData",
        render: (val: any[]) => val.length
      },
      {
        title: "Total rollbacks",
        dataIndex: "rollbackData",
        key: "rollbackData",
        render: (val: any[]) => val.length
      },
      {
        title: "Check",
        dataIndex: "check",
        key: "check",
        render: (val: any, data: any) => {
          return (
            <Link to={`/user/${data.userId}`}>
              <Button type="primary">Check</Button>
            </Link>
          );
        }
      }
    ],
    [userStore]
  );

  return (
    <Layout style={{ padding: "24px" }}>
      <Layout.Content
        style={{
          padding: 24,
          margin: 0,
          minHeight: 280
        }}
      >
        <Typography>
          <Title>Slots users</Title>
        </Typography>
        <Table
          className="table-min"
          dataSource={userStore.slotsUsersList}
          columns={columns}
          loading={isLoading}
          scroll={{ x: true }}
          rowKey={(record) => record._id}
        />
      </Layout.Content>
    </Layout>
  );
});
