import { useCallback, useEffect, useMemo, useState } from "react";
import { Layout } from "antd";
import { observer } from "mobx-react-lite";
import { Typography, Table, Button, Row } from "antd";
import { cutString } from "@/utils/strings";
import { useUserStore } from "@/providers/BaseStoresProvider";
import { IWithdraw } from "@/models/interfaces/IUserStore";
import { IoCheckboxSharp, IoCloseCircle } from "react-icons/io5";
import { unixTsToDateStr } from "@/utils/dates";

const { Title, Text } = Typography;

enum TX_STATUS {
  SUCCESS = 1,
  ERROR = 2,
  PENDING = 3
}

export enum INVOICE_STATUS {
  CREATED,
  REVIEW,
  SUCCESS,
  FAILED,
  APPEAL
}

export const ManagePage = observer((): JSX.Element => {
  const [isLoading, setLoading] = useState<boolean>(true);
  const userStore = useUserStore();

  const loadRequests = useCallback(async () => {
    setLoading(true);
    await userStore.loadWithdrawRequest();
    setLoading(false);
  }, []);

  useEffect(() => {
    loadRequests();
  }, [loadRequests]);

  const approveClick = async (data: IWithdraw) => {
    console.log(data, "approveClick");
    if (data.status !== TX_STATUS.PENDING) return;
    await userStore.submitWithdraw(data);
    loadRequests();
  };

  const approveFiatClick = async (data: IWithdraw) => {
    console.log(data, "approveClick2");
    if (data.status === INVOICE_STATUS.SUCCESS) return;
    await userStore.submitWithdrawFiat(data);
    loadRequests();
  };

  const declineClick = async (data: IWithdraw) => {
    console.log(data, "declineClick");
    await userStore.submitWithdraw({
      ...data,
      status: TX_STATUS.ERROR
    });
    loadRequests();
  };

  const columns: any = useMemo(
    () => [
      {
        title: "ID",
        dataIndex: "txNonce",
        key: "txNonce"
      },
      {
        title: "Created",
        dataIndex: "createdAtUnix",
        key: "createdAtUnix",
        render: (val: number) => unixTsToDateStr(val, "DD.MM.YYYY HH:mm"),
        defaultSortOrder: "descend",
        sorter: (a: any, b: any) => a.createdAtUnix - b.createdAtUnix
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        className: "vertical-middle",
        render: (val: number) => (
          <Row align="middle" justify="center">
            {val === TX_STATUS.SUCCESS ? (
              <IoCheckboxSharp color="green" size={30} />
            ) : val === TX_STATUS.ERROR ? (
              <IoCloseCircle color="red" size={30} />
            ) : (
              <IoCloseCircle color="#ffc75a" size={30} />
            )}
          </Row>
        )
      },
      {
        title: "Withdraw to",
        dataIndex: "address",
        key: "address",
        width: 150,
        render: (val: string) => (
          <Text
            copyable={{
              text: val ?? ""
            }}
          >
            {cutString(val, 4, 5)}
          </Text>
        )
      },
      {
        title: "Alias",
        dataIndex: "currency",
        key: "currency"
      },
      {
        title: "Amount",
        dataIndex: "amount",
        key: "amount"
      },
      {
        title: "Chain",
        dataIndex: "chain",
        key: "chain"
      },
      {
        title: "User ID",
        dataIndex: "userId",
        key: "userId",
        width: 150,
        render: (val: string) => (
          <Text
            copyable={{
              text: val ?? ""
            }}
          >
            {cutString(val, 4, 5)}
          </Text>
        )
      },
      {
        title: "Approve",
        key: "approve",
        render: (record: IWithdraw) => (
          <Button
            onClick={() => {
              approveClick(record);
            }}
            type="primary"
            size="middle"
            disabled={![TX_STATUS.PENDING].includes(record.status)}
          >
            Approve
          </Button>
        )
      },
      {
        title: "Decline",
        key: "decline",
        render: (record: IWithdraw) => (
          <Button
            onClick={() => {
              declineClick(record);
            }}
            type="primary"
            size="middle"
            danger
            disabled={![TX_STATUS.PENDING].includes(record.status)}
          >
            Decline
          </Button>
        )
      }
    ],
    [userStore]
  );

  const columnsFiat: any = useMemo(
    () => [
      {
        title: "ID",
        dataIndex: "orderId",
        key: "orderId"
      },
      {
        title: "Created",
        dataIndex: "lastUpdateUnix",
        key: "lastUpdateUnix",
        render: (val: number) => unixTsToDateStr(val, "DD.MM.YYYY HH:mm"),
        defaultSortOrder: "descend",
        sorter: (a: any, b: any) => a.createdAtUnix - b.createdAtUnix
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        className: "vertical-middle",
        render: (val: number) => (
          <Row align="middle" justify="center">
            {val === INVOICE_STATUS.SUCCESS ? (
              <IoCheckboxSharp color="green" size={30} />
            ) : val === TX_STATUS.ERROR ? (
              <IoCloseCircle color="red" size={30} />
            ) : (
              <IoCloseCircle color="#ffc75a" size={30} />
            )}
          </Row>
        )
      },
      {
        title: "Withdraw to",
        dataIndex: "withdrawTo",
        key: "withdrawTo",
        width: 150,
        render: (val: string) => (
          <Text
            copyable={{
              text: val ?? ""
            }}
          >
            {cutString(val, 4, 5)}
          </Text>
        )
      },
      {
        title: "Alias",
        dataIndex: "currencyName",
        key: "currencyName"
      },
      {
        title: "Amount",
        dataIndex: "amount",
        key: "amount"
      },
      {
        title: "User ID",
        dataIndex: "userId",
        key: "userId",
        width: 150,
        render: (val: string) => (
          <Text
            copyable={{
              text: val ?? ""
            }}
          >
            {cutString(val, 4, 5)}
          </Text>
        )
      },
      {
        title: "Approve",
        key: "approve",
        render: (record: IWithdraw) => (
          <Button
            onClick={() => {
              approveFiatClick(record);
            }}
            type="primary"
            size="middle"
            disabled={[INVOICE_STATUS.SUCCESS].includes(record.status)}
          >
            Approve
          </Button>
        )
      },
      {
        title: "Decline",
        key: "decline",
        render: (record: IWithdraw) => (
          <Button
            type="primary"
            size="middle"
            danger
            disabled={[INVOICE_STATUS.FAILED, INVOICE_STATUS.SUCCESS].includes(
              record.status
            )}
          >
            Decline
          </Button>
        )
      }
    ],
    [userStore]
  );

  return (
    <Layout style={{ padding: "24px" }}>
      <Layout.Content
        style={{
          padding: 24,
          margin: 0,
          minHeight: 280
        }}
      >
        <Typography>
          <Title>Withdraw CRYPTO</Title>
        </Typography>
        <Table
          className="table-min"
          dataSource={userStore.withdrawRequests}
          columns={columns}
          loading={isLoading}
          scroll={{ x: true }}
          rowKey={(record) => record.txNonce}
        />
        <Typography>
          <Title>Withdraw FIAT</Title>
        </Typography>
        <Table
          className="table-min"
          dataSource={userStore.withdrawRequestsFiat}
          columns={columnsFiat}
          loading={isLoading}
          scroll={{ x: true }}
          rowKey={(record) => record.orderId}
        />
      </Layout.Content>
    </Layout>
  );
});
