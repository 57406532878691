import { useEffect, useState } from "react";
import { Button, Layout, Row } from "antd";
import { observer } from "mobx-react-lite";
import { useNavigate, useParams } from "react-router-dom";
import { Typography } from "antd";
import { useUserStore } from "@/providers/BaseStoresProvider";
import { GamesTable } from "./components/gamesTable";
import { CrashTable } from "./components/crashTable";

import { cutString } from "@/utils/strings";
import { CoinflipTable } from "./components/coinflipTable";
import { MinesTable } from "./components/minesTable";
import { LotteryTable } from "./components/lotteryTable";
import { PlinkoTable } from "./components/plinkoTable";
import { SlotsTable } from "./components/slotsTable";

const { Title, Text } = Typography;
export const UserPage = observer((): JSX.Element => {
  const navigate = useNavigate();
  const params = useParams();
  const [isLoading, setLoading] = useState<boolean>(true);
  const userStore = useUserStore();

  useEffect(() => {
    const asyncFunc = async () => {
      if (!userStore.connected || !params?.id) return;
      await userStore.loadGamesForUser(params.id);
      await userStore.loadSlotsForUser(params.id);
      await userStore.loadLotterieSingleData(params.id);
      await userStore.loadSearchUser({ id: params.id });
      setLoading(false);
    };

    asyncFunc();
  }, [userStore.connected, navigate, params]);

  if (!userStore.connected) return <></>;

  return (
    <Layout style={{ padding: "24px" }}>
      <Layout.Content
        style={{
          padding: 24,
          margin: 0,
          minHeight: 280
        }}
      >
        <Typography>
          <Row justify="space-between" align="middle">
            <Title>User page {cutString(params.id ?? "0x", 4, 3)}</Title>

            {params.id && (
              <Button
                style={{
                  background: userStore.userDetailedInfo?.disUser
                    ? "green"
                    : "red",
                  color: "white"
                }}
                type="default"
                onClick={() =>
                  userStore.deleteOrDisableUser(params.id as string, true)
                }
              >
                {userStore.userDetailedInfo?.disUser ? "Enable" : "Disable"}
              </Button>
            )}
          </Row>
          <Title level={2}>Crash</Title>
          <CrashTable
            gamesList={userStore.allUserGames.crash?.slice().reverse()}
            isLoading={isLoading}
          />

          <Title level={2}>
            Slots (total: {userStore.allUserSlots?.betData?.length ?? 0})
          </Title>
          <SlotsTable
            gamesList={userStore.allUserSlots}
            isLoading={isLoading}
          />

          <Title level={2}>Lottery</Title>
          <div className="lottery-user">
            <div className="lottery-user__col">
              <Title level={4}>Total wins in dollar</Title>
              <Text>
                {userStore.allUserLottery.lotteryUserData.totalWinsInDollar}{" "}
                USDT
              </Text>
            </div>
            <div className="lottery-user__col">
              <Title level={4}>Total win tickets</Title>
              <Text>
                {userStore.allUserLottery.lotteryUserData.totalWinsTickets}{" "}
                tickets
              </Text>
            </div>
            <div className="lottery-user__col">
              <Title level={4}>Total tickets bought</Title>
              <Text>
                {userStore.allUserLottery.lotteryUserData.totalTickets} tickets
              </Text>
            </div>
          </div>

          <LotteryTable
            gamesList={userStore.allUserLottery.lottery?.slice().reverse()}
            isLoading={isLoading}
          />

          <Title level={2}>Limbo</Title>
          <GamesTable
            gamesList={userStore.allUserGames.limbo?.slice().reverse()}
            isLoading={isLoading}
          />

          <Title level={2}>Hashdice</Title>
          <GamesTable
            gamesList={userStore.allUserGames.hashdice?.slice().reverse()}
            isLoading={isLoading}
          />

          <Title level={2}>Plinko</Title>
          <PlinkoTable
            gamesList={userStore.allUserGames.plinko?.slice().reverse()}
            isLoading={isLoading}
          />

          <Title level={2}>Mines</Title>
          <MinesTable
            gamesList={userStore.allUserGames.mines?.slice().reverse()}
            isLoading={isLoading}
          />

          <Title level={2}>Coinflip</Title>
          <CoinflipTable
            gamesList={userStore.allUserGames.coinflip?.slice().reverse()}
            isLoading={isLoading}
          />

          <Title level={2}>Classic Dice</Title>
          <CoinflipTable
            gamesList={userStore.allUserGames.classicDice?.slice().reverse()}
            isLoading={isLoading}
          />
        </Typography>
      </Layout.Content>
    </Layout>
  );
});
