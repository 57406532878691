import { useEffect, useState, ChangeEvent } from "react";
import { Layout } from "antd";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import { Typography, Row, Button, Col, Input, Card } from "antd";
import { useUserStore } from "@/providers/BaseStoresProvider";
import { UsersTable } from "./components/usersTable";

const { Title } = Typography;
const { Search } = Input;

const mainBtnStyle: React.CSSProperties = {
  display: "flex",
  alignItems: "center",
  height: "80px",
  width: "70%",
  maxWidth: "300px",
  justifyContent: "center",
  margin: "0 auto",
  fontSize: "20px",
  fontWeight: "600"
};

export const HomePage = observer((): JSX.Element => {
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState<boolean>(true);
  const [searchVal, setSearch] = useState<string>("");
  const userStore = useUserStore();

  const onLoadUsers = async () => {
    setLoading(true);
    await userStore.loadAllUsers({ page: "1", limit: "50" });
    setLoading(false);
  };

  // const onMigrate = async () => {
  //   await userStore.migrateUsers();
  // };

  const onSearchSubmit = async () => {
    setLoading(true);
    userStore.loadSearchUser({ id: searchVal });
    setLoading(false);
  };

  useEffect(() => {
    if (userStore.connected) onLoadUsers();
  }, [userStore.connected, navigate]);

  const onSearch = async (value: ChangeEvent<HTMLInputElement>) => {
    setSearch(value.currentTarget.value);
  };
  return (
    <Layout style={{ padding: "24px" }}>
      <Layout.Content
        style={{
          padding: 24,
          margin: 0,
          minHeight: 280
        }}
      >
        {!userStore.connected ? (
          <Row
            style={{ minHeight: "80vh", padding: "0 20px" }}
            gutter={24}
            align="middle"
            justify="space-between"
          >
            <Col
              flex="flex"
              span={24}
              style={{ textAlign: "center", padding: "0 30px" }}
            >
              <Title
                style={{
                  margin: "40px auto",
                  whiteSpace: "nowrap",
                  textAlign: "center"
                }}
              >
                Connect to admin panel
              </Title>
              <Button
                size="large"
                type="primary"
                onClick={() => userStore.handleClickMetamask()}
                style={mainBtnStyle}
              >
                CONNECT
              </Button>
            </Col>
          </Row>
        ) : (
          <Typography>
            <div className="row">
              <Title>Main page</Title>
              <Button type="primary" onClick={onLoadUsers}>
                Update list
              </Button>
            </div>
            <Card
              style={{ margin: "20px 0 40px 0" }}
              type="inner"
              title="Search..."
            >
              <Search
                placeholder="input search text"
                enterButton="Search"
                size="large"
                onChange={onSearch}
                onSearch={onSearchSubmit}
                value={searchVal}
              />
            </Card>
            <UsersTable isLoading={isLoading} />
          </Typography>
        )}
      </Layout.Content>
    </Layout>
  );
});
