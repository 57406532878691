import { useMemo, useState } from "react";
import { Button, Card } from "antd";
import { observer } from "mobx-react-lite";
import { Typography, Table, Row, Col } from "antd";
import { cutString } from "@/utils/strings";
import { useUserStore } from "@/providers/BaseStoresProvider";
import BN from "@/utils/BN";
import { Link } from "react-router-dom";
import { unixTsToDateStr } from "@/utils/dates";
import classNames from "classnames";

const { Text } = Typography;

// const ADMIN_FEE = 0.3;
// const REV_SHARE = 0.4;

export const UsersTable = observer(
  ({ isLoading }: { isLoading: boolean }): JSX.Element => {
    const userStore = useUserStore();
    const [rowsLoading, setRowLoading] = useState<string[]>([]);

    const columns: any = useMemo(
      () => [
        {
          title: "ID",
          dataIndex: "id",
          key: "id",
          render: (val: string) => (
            <Text
              copyable={{
                text: val ?? ""
              }}
            >
              {cutString(val, 4, 5)}
            </Text>
          )
        },
        {
          title: "Created",
          dataIndex: "createdAtUnix",
          key: "createdAtUnix",
          render: (val: number) => unixTsToDateStr(val, "DD.MM.YYYY HH:mm:ss"),
          defaultSortOrder: "descend",
          sorter: (a: any, b: any) => a.createdAtUnix - b.createdAtUnix
        },
        {
          title: "Email / name / web3",
          dataIndex: "email/name",
          key: "name",
          render: (val: string, full: any) => {
            return (
              <div
                className={classNames(full?.disUser && "cell--red", "tr--cell")}
              >
                {full?.email ? (
                  <Text
                    className={full?.emailVerified && "green"}
                    copyable={{
                      text: full?.email ?? ""
                    }}
                  >
                    {cutString(full?.email, 8, 3)}
                  </Text>
                ) : (
                  "-"
                )}
                {full?.name ? (
                  <>
                    /{" "}
                    <Text
                      copyable={{
                        text: full?.name ?? ""
                      }}
                    >
                      {cutString(full?.name, 6, 3)}
                    </Text>
                  </>
                ) : (
                  "-"
                )}
                {full?.walletAddress ? (
                  <>
                    /{" "}
                    <Text
                      copyable={{
                        text: full?.walletAddress ?? ""
                      }}
                    >
                      {cutString(full?.walletAddress, 4, 5)}
                    </Text>
                  </>
                ) : null}
              </div>
            );
          }
        },
        {
          title: "XP",
          dataIndex: "totalXp",
          key: "totalXp"
        },
        {
          title: "Deposits",
          dataIndex: "totalDeposits",
          key: "totalDeposits",
          render: (val: any[]) => {
            return val + " $";
          },
          sorter: (a: any) => a
        },
        {
          title: "Withdraw",
          dataIndex: "totalWithdraw",
          key: "totalWithdraw",
          render: (val: any[]) => {
            return val + " $";
          },
          sorter: (a: any) => a
        },
        {
          title: "Wagered",
          dataIndex: "wageredForBonus",
          key: "wageredForBonus",
          render: (val: string) =>
            new BN(val).times(0.01).times(0.25).toFixed(4) + " $",
          sorter: (a: any) => a
        },
        {
          title: "PROMOS+",
          dataIndex: "confirmedCodes",
          key: "confirmedCodes",
          render: (val: string[]) => {
            return val?.length === 0 ? "-" : val?.join(",");
          }
        },
        {
          title: "Locked",
          dataIndex: "lockedBonus",
          key: "lockedBonus",
          render: (val: string) => Number(val).toFixed(2) + " $"
        },
        {
          title: "Claimed",
          dataIndex: "bonusesHistory",
          key: "bonusesHistory",
          render: (val: any[]) => {
            if (!val || val?.length === 0) return "0 $";
            const total = val.reduce((acc, curr) => {
              return curr?.type === "claim" ? acc.plus(curr.amount) : acc;
            }, new BN(0));

            return total.toFixed(2) + " $";
          }
          // sorter: (a: any, b: any) => {
          //   const totalA = a.bonusesHistory.reduce((acc: any, curr: any) => {
          //     return curr?.type === "claim" ? acc.plus(curr.amount) : acc;
          //   }, new BN(0));
          //   const totalB = b.bonusesHistory.reduce((acc: any, curr: any) => {
          //     return curr?.type === "claim" ? acc.plus(curr.amount) : acc;
          //   }, new BN(0));

          //   return totalA.minus(totalB);
          // }
        },
        {
          title: "Revenue",
          dataIndex: "rev",
          key: "rev",
          render: (val: any[], full: any) => {
            return (
              new BN(full.totalDeposits).minus(full.totalWithdraw).toFixed(2) +
              " $"
            );
          },
          sorter: (a: any, b: any) => {
            const totalA = new BN(a.totalDeposits).minus(a.totalWithdraw);
            const totalB = new BN(b.totalDeposits).minus(b.totalWithdraw);

            return totalA.minus(totalB);
          }
        },
        // {
        //   title: "Relogin",
        //   dataIndex: "Relogin",
        //   key: "Relogin",
        //   render: (val: any, data: any) => (
        //     <Button onClick={() => userStore.reloginUser(data.id)}>
        //       Relogin
        //     </Button>
        //   )
        // },
        {
          title: "Action",
          dataIndex: "check",
          key: "check",
          render: (val: any, data: any) => {
            return (
              <Row>
                <Link to={`/user/${data.id}`} style={{ marginRight: "10px" }}>
                  <Button type="primary">Check</Button>
                </Link>
                <Button
                  style={{ background: "red", color: "white" }}
                  type="default"
                  onClick={() => userStore.deleteOrDisableUser(data.id, false)}
                >
                  Delete
                </Button>
              </Row>
            );
          }
        }
      ],
      [userStore]
    );

    const handleChange = (val: any, a: any, b: any, c: any) => {
      console.log(val, "___VAL");
      console.log(a, "__a");
      console.log(b, "__b");
      console.log(c, "__c");
      if (c?.action === "sort" && b?.order) {
        userStore.loadAllUsers({
          page: val?.current,
          limit: val?.pageSize,
          totalDeposits: b?.field === "totalDeposits" ? "1" : "0",
          totalWithdraw: b?.field === "totalWithdraw" ? "1" : "0",
          totalWagered: b?.field === "wageredForBonus" ? "1" : "0"
        });
        return;
      }

      userStore.loadAllUsers({
        page: val?.current,
        limit: val?.pageSize
      });
    };

    return (
      <Table
        className="table-min"
        dataSource={userStore.usersList}
        columns={columns}
        scroll={{ x: true }}
        loading={isLoading}
        rowKey={(record) => record.id}
        onChange={handleChange}
        pagination={{
          pageSize: 50,
          total: userStore.usersPagination?.totalItems ?? 10
        }}
        expandable={{
          onExpand: async (isOpen, record) => {
            if (isOpen) {
              setRowLoading((s) => [...s, record.id.toString() ?? ""]);
              await userStore.loadUserWallets(record.id);
              await userStore.loadUserLoginDatas(record.id);

              setRowLoading((s) => {
                const index = s.indexOf(record.id.toString() ?? "");
                s.splice(index, 1);
                return [...s];
              });
            }
          },
          expandedRowRender: (record) => {
            const wallets = userStore.loadedUsersWallets.find((_) => {
              const key = Object.keys(_);
              if (key[0] === record.id) return _;
            });

            const loginDatas = userStore.loadedLoginsData.find((_) => {
              const key = Object.keys(_);
              if (key[0] === record.id) return _;
            });

            if (!wallets) return <div>No wallets</div>;

            if (rowsLoading.includes(record.id)) {
              return (
                <Card
                  style={{ maxWidth: "40%", margin: "0 auto" }}
                  type="inner"
                  bordered
                  title={`Wallets for ${record.id}`}
                >
                  Loading...
                </Card>
              );
            }

            return (
              <div className="row-table">
                <Card
                  style={{ maxWidth: "40%", margin: "0 auto" }}
                  type="inner"
                  bordered
                  title={`Wallets for ${record.id}`}
                >
                  {Object.values(wallets)[0].map((_, i) => (
                    <Row justify="space-between" key={_.id}>
                      <Text color="grey">{_.alias}:</Text>{" "}
                      <Text>{_.amount}</Text>
                    </Row>
                  ))}
                </Card>
                <Card
                  style={{ maxWidth: "40%", margin: "0 auto" }}
                  type="inner"
                  bordered
                  title={`LOGIN history for ${record.id}`}
                >
                  {loginDatas &&
                    Object.values(loginDatas)[0].map((_, i) => (
                      <Col className="card-ip">
                        <Row
                          style={{ marginBottom: "10px" }}
                          justify="space-between"
                          key={i}
                        >
                          <Text color="grey">Device</Text>{" "}
                          <Text>{_.deviceFull}</Text>
                        </Row>
                        <Row
                          style={{ marginBottom: "10px" }}
                          justify="space-between"
                          key={i}
                        >
                          <Text color="grey">IP</Text> <Text>{_.ipFull}</Text>
                        </Row>
                        <Row
                          style={{ marginBottom: "10px" }}
                          justify="space-between"
                          key={i}
                        >
                          <Text color="grey">Time</Text>{" "}
                          <Text>
                            {unixTsToDateStr(
                              new BN(_.loginTime ?? 0).toNumber(),
                              "DD.MM.YYYY HH:mm:ss"
                            )}
                          </Text>
                        </Row>
                      </Col>
                    ))}
                </Card>
              </div>
            );
          },
          rowExpandable: (record) => record.id !== null
        }}
      />
    );
  }
);
