import { useMemo } from "react";
import { observer } from "mobx-react-lite";
import { Typography, Table } from "antd";
import { cutString } from "@/utils/strings";
import { useUserStore } from "@/providers/BaseStoresProvider";
import { unixTsToDateStr } from "@/utils/dates";
import { ISlotsList } from "@/models/interfaces/IUserStore";

const { Text, Title } = Typography;

interface IProps {
  isLoading: boolean;
  gamesList: ISlotsList | null;
}

export const SlotsTable = observer(
  ({ isLoading, gamesList }: IProps): JSX.Element => {
    const userStore = useUserStore();
    const columnsBets: any = useMemo(
      () => [
        {
          title: "ID",
          dataIndex: "_id",
          key: "_id",
          render: (val: string) => (
            <Text
              copyable={{
                text: val ?? ""
              }}
            >
              {cutString(val, 4, 5)}
            </Text>
          )
        },
        {
          title: "Balance",
          dataIndex: "balanceCurr",
          key: "balanceCurr",
          render: (val: number, full: any) => {
            const amount = full?.balanceAmount ? full?.balanceAmount : "0";
            const symbol = full?.balanceCurr ? full?.balanceCurr : "-";

            return `${amount} ${symbol}`;
          }
        },
        {
          title: "Time",
          dataIndex: "time",
          key: "time",
          render: (val: number) =>
            val ? unixTsToDateStr(val, "DD.MM.YYYY HH:mm:ss") : 0,
          defaultSortOrder: "descend",
          sorter: (a: any, b: any) => a.time - b.time
        },
        {
          title: "Amount",
          dataIndex: "amount",
          key: "amount",
          render: (val: number, full: any) => `${val} ${full?.balanceCurr}`
        },
        {
          title: "Type",
          dataIndex: "type",
          key: "type"
        },
        {
          title: "Game ID",
          dataIndex: "game_uuid",
          key: "game_uuid"
        },
        {
          title: "Transaction ID",
          dataIndex: "transactionId",
          key: "transactionId"
        }
      ],
      [userStore]
    );
    const columnsRb = useMemo(
      () => [
        {
          title: "ID",
          dataIndex: "_id",
          key: "_id",
          render: (val: string) => (
            <Text
              copyable={{
                text: val ?? ""
              }}
            >
              {cutString(val, 4, 5)}
            </Text>
          )
        },
        {
          title: "Balance",
          dataIndex: "balanceCurr",
          key: "balanceCurr",
          render: (val: number, full: any) => {
            const amount = full?.balanceAmount ? full?.balanceAmount : "0";
            const symbol = full?.balanceCurr ? full?.balanceCurr : "-";

            return `${amount} ${symbol}`;
          }
        },
        {
          title: "Time",
          dataIndex: "time",
          key: "time",
          render: (val: number) =>
            val ? unixTsToDateStr(val, "DD.MM.YYYY HH:mm") : 0
        },
        {
          title: "Transaction ID",
          dataIndex: "transactionId",
          key: "transactionId"
        },
        {
          title: "Revert txs",
          dataIndex: "revertTransactions",
          key: "revertTransactions",
          render: (val: any[]) => val.length
        }
      ],
      [userStore]
    );

    if (!gamesList) return <></>;

    return (
      <div>
        <Title level={4}>All bets</Title>
        <Table
          className="table-min"
          dataSource={gamesList.betData}
          columns={columnsBets}
          scroll={{ x: true }}
          loading={isLoading}
          rowKey={(record) => record._id}
        />
        <Title level={4}>All rollbacks</Title>
        <Table
          className="table-min"
          dataSource={gamesList.rollbackData}
          columns={columnsRb}
          scroll={{ x: true }}
          loading={isLoading}
          rowKey={(record) => record._id}
        />
      </div>
    );
  }
);
