import { useCallback, useEffect, useMemo, useState } from "react";
import { Card, Layout } from "antd";
import { observer } from "mobx-react-lite";
import { Typography, Table, Button, Row } from "antd";
import { useUserStore } from "@/providers/BaseStoresProvider";
import { unixTsToDateStr } from "@/utils/dates";
import BN from "bignumber.js";

export const SwapsPage = observer((): JSX.Element => {
  const [isLoading, setLoading] = useState<boolean>(true);
  const [rowsLoading, setRowLoading] = useState<string[]>([]);
  const userStore = useUserStore();

  const loadRequests = useCallback(async () => {
    setLoading(true);
    await userStore.loadBanksGames();
    await userStore.loadSwaps();
    setLoading(false);
  }, []);

  useEffect(() => {
    loadRequests();
  }, [loadRequests]);

  const columns: any = useMemo(
    () => [
      {
        title: "ID",
        dataIndex: "userId",
        key: "userId"
      },
      {
        title: "Swaps counter",
        dataIndex: "counter",
        key: "counter"
      }
    ],
    [userStore]
  );

  console.log(userStore.tokenPrice, "__userStoretokenPrice");
  const columnsDetailed: any = useMemo(
    () => [
      {
        title: "Pair",
        dataIndex: "fromAlias",
        key: "fromAlias",
        render: (val: string, full: any) => {
          return `${full?.fromAlias} -> ${full?.toAlias}`;
        }
      },
      {
        title: "Swap Amount",
        dataIndex: "amount",
        key: "amount",
        render: (val: string, full: any) => {
          return `${val ?? "0"} ${full?.fromAlias}`;
        }
      },
      {
        title: "Balance before",
        dataIndex: "balanceFromAfter",
        key: "balanceFromAfter",
        render: (val: string[], full: any) => {
          return (
            <div>
              <div>
                {`${new BN(full?.balanceFromBefore).toFixed(4)} ${
                  full?.fromAlias
                }` +
                  " / " +
                  `${new BN(full?.balanceToBefore).toFixed(4)} ${
                    full?.toAlias
                  }`}
              </div>
              <div>
                {`${new BN(full?.balanceFromBefore)
                  .times(userStore.tokenPrice[full?.fromAlias] ?? 0)
                  .toFixed(0)} $` +
                  " / " +
                  `${new BN(full?.balanceToBefore)
                    .times(userStore.tokenPrice[full?.toAlias] ?? 0)
                    .toFixed(2)} $`}
              </div>
            </div>
          );
        }
      },
      {
        title: "Balance after",
        dataIndex: "balanceToAfter",
        key: "balanceToAfter",
        render: (val: string[], full: any) => {
          return (
            <div>
              <div>
                {`${new BN(full?.balanceFromAfter).toFixed(4)} ${
                  full?.fromAlias
                }` +
                  " / " +
                  `${new BN(full?.balanceToAfter).toFixed(4)} ${full?.toAlias}`}
              </div>
              <div>
                {`${new BN(full?.balanceFromAfter)
                  .times(userStore.tokenPrice[full?.fromAlias] ?? 0)
                  .toFixed(0)} $` +
                  " / " +
                  `${new BN(full?.balanceToAfter)
                    .times(userStore.tokenPrice[full?.toAlias] ?? 0)
                    .toFixed(0)} $`}
              </div>
            </div>
          );
        }
      },
      {
        title: "Created",
        dataIndex: "createdAtUnix",
        key: "createdAtUnix",
        render: (val: number) => unixTsToDateStr(val, "DD.MM.YYYY HH:mm:ss"),
        defaultSortOrder: "descend",
        sorter: (a: any, b: any) => a.createdAtUnix - b.createdAtUnix
      }
    ],
    [userStore, userStore.tokenPrice]
  );

  return (
    <Layout style={{ padding: "24px" }}>
      <Layout.Content
        style={{
          padding: 24,
          margin: 0,
          minHeight: 280
        }}
      >
        <Table
          dataSource={userStore.swapsList}
          columns={columns}
          loading={isLoading}
          scroll={{ x: true }}
          rowKey={(record) => record.userId}
          expandable={{
            onExpand: async (isOpen, record) => {
              if (isOpen) {
                setRowLoading((s) => [...s, record.userId.toString() ?? ""]);
                await userStore.loadSwapsDetailed(record.userId);

                setRowLoading((s) => {
                  const index = s.indexOf(record.userId.toString() ?? "");
                  s.splice(index, 1);
                  return [...s];
                });
              }
            },
            expandedRowRender: (record) => {
              const swapsData = userStore.swapsDetailed.find((_) => {
                const key = Object.keys(_);
                if (key[0] === record.userId) return _;
              });

              if (!swapsData) return <div>No swaps</div>;

              if (rowsLoading.includes(record.userId)) {
                return (
                  <Card
                    style={{ maxWidth: "40%", margin: "0 auto" }}
                    type="inner"
                    bordered
                    title={`Swaps for ${record.userId}`}
                  >
                    Loading...
                  </Card>
                );
              }

              return (
                <Table
                  className="table-inner"
                  dataSource={Object.values(swapsData)[0]}
                  columns={columnsDetailed}
                  scroll={{ x: true }}
                  loading={isLoading}
                  rowKey={(record) => record._id}
                />
              );
            },
            rowExpandable: (record) => record.userId
          }}
        />
      </Layout.Content>
    </Layout>
  );
});
