import React from "react";
import { observer } from "mobx-react-lite";
import { Header } from "@/components/header";
import { Navbar } from "@/components/navbar";
import {
  ManagePage,
  HomePage,
  UserPage,
  LotteryPage,
  SlotsPage
} from "@/pages";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import {
  ProvideBaseStores,
  useUserStore
} from "@/providers/BaseStoresProvider";
import { Layout, ConfigProvider, theme } from "antd";
import ROUTES from "@/routes";
import { ReferralsPage } from "./pages/referrals";
import { BanksPage } from "./pages/banks";
import { CommentsPage } from "./pages/Comments";
import { SwapsPage } from "./pages/swaps";

const RenderLayout = observer(({ children }: any) => {
  const userStore = useUserStore();

  return (
    <ConfigProvider
      theme={{
        algorithm: userStore.themeDark
          ? theme.darkAlgorithm
          : theme.defaultAlgorithm,
        token: {
          colorPrimary: "#7216e8"
        }
      }}
    >
      <main className="main">
        <Layout>
          <Header />
          {!userStore?.connected ? <HomePage /> : null}
          {userStore.connected ? (
            <Layout>
              <Navbar />
              <div className="main-content">{children}</div>
            </Layout>
          ) : null}
        </Layout>
      </main>
    </ConfigProvider>
  );
});

export const App = observer(() => {
  return (
    <ProvideBaseStores>
      <BrowserRouter>
        <RenderLayout>
          <Routes>
            <Route path={ROUTES.manage.path} element={<ManagePage />} />
            <Route path={ROUTES.index.path} element={<HomePage />} />
            <Route path={ROUTES.user.path} element={<UserPage />} />
            <Route path={ROUTES.referrals.path} element={<ReferralsPage />} />
            <Route path={ROUTES.lottery.path} element={<LotteryPage />} />
            <Route path={ROUTES.swaps.path} element={<SwapsPage />} />
            <Route path={ROUTES.banks.path} element={<BanksPage />} />
            <Route path={ROUTES.slots.path} element={<SlotsPage />} />
            <Route path={ROUTES.comments.path} element={<CommentsPage />} />
            <Route
              path="*"
              element={<Navigate to={ROUTES.index.path} replace />}
            />
          </Routes>
        </RenderLayout>
      </BrowserRouter>
    </ProvideBaseStores>
  );
});
